.notification-form {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    width: 90%;
    max-width: 900px; /* Reduced max-width for better fit */
    margin: auto;
}

.notification-input {
    height: 40px;
    margin: 3px;
    border: 1px solid #333;
    border-radius: 5px;
    font-size: 16px;
    padding: 0 10px;
    width: 100%; /* Make the input take full available width */
    max-width: 200px; /* Limit the maximum width */
    background-color: #333;
    color: white;
    box-sizing: border-box;
}

.notification-button {
    height: 40px;
    margin: 3px;
    padding: 0 15px;
    background-color: #fff;
    color: #333;
    cursor: pointer;
    border-radius: 5px;
    font-weight: 700;
    white-space: nowrap;
    flex-shrink: 0;
    max-width: 120px; /* Limit the maximum width of the button */
}

.notification-button:hover {
    background-color: #ddd;
}

.error-message,
.success-message {
    font-size: 14px;
    margin-top: 10px;
    text-align: center;
}

.message-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

/* Media queries for responsiveness */
@media (max-width: 1000px) {
    .notification-input {
        max-width: 180px; /* Reduce max-width as screen size decreases */
        font-size: 14px;
        height: 35px;
    }

    .notification-button {
        max-width: 100px; /* Reduce button width */
        font-size: 14px;
        height: 35px;
    }
}

@media (max-width: 800px) {
    .notification-form {
        width: 100%;
        padding: 0 10px;
    }

    .notification-input {
        max-width: 160px; /* Further reduce width */
        font-size: 13px;
        height: 35px;
    }

    .notification-button {
        max-width: 90px;
        font-size: 13px;
        height: 35px;
    }
}

@media (max-width: 600px) {
    .notification-form {
        flex-direction: column; /* Switch to column layout on small screens */
    }

    .notification-input,
    .notification-button {
        width: 100%; /* Inputs and button take full width */
        max-width: 100%; /* Ensure no overflow */
        font-size: 14px;
        height: 35px;
    }

    .notification-button {
        margin-top: 10px; /* Add space between inputs and button */
    }
}


/* Loader styling */
.loader {
    border: 5px solid rgba(0, 0, 0, 0.1); /* Light grey background */
    border-left: 5px solid #28E0A1; /* Green color */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    margin: 0 auto; /* Center the spinner */
}

/* Keyframes for spinner animation */
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
