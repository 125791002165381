/* Title.css */

body {
    background: linear-gradient(16deg, rgba(40,224,161,1) 46%, rgba(0,0,0,1) 39%);
    color: white;
    font-family: Arial, sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    margin: 0;
    padding: 20px;
  }
  
  .container {
    width: 100%;
    max-width: 1000px;
    padding: 5%;
    background-color: black;
    border-radius: 12px;
    text-align: left;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .header {
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
  }
  
  .headerText {
    font-size: 3vw;
    font-weight: bold;
    min-width: 200px;
    text-align: center;
  }
  
  .badge {
    font-size: 2vw;
    font-weight: bold;
    color: #D1D5DB;
    padding: 4px 8px;
    border-radius: 12px;
    margin-left: 8px;
    border: 3px solid #28E0A1;
    min-width: 100px;
  }
  
  .heading {
    font-size: 2vw;
    font-weight: 800;
    line-height: 1.2;
    margin: 0;
    text-align: center;
  }
  
  .highlight {
    color: #28E0A1;
  }
  
  .paragraph {
    margin-top: 16px;
    font-size: 1.2vw;
    color: #D1D5DB;
  }
  
  .ctaButton {
    margin-top: 24px;
    width: 10vw;
    height: 10vw;
    background-color: #784CC0;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: white;
    font-size: 1.5vw;
  }
  
  .paragraph2 {
    margin: auto;
    font-size: 1.0vw;
    color: #D1D5DB;
    opacity: 0.7;
    text-align: center;
    margin-top: 10px;
  }
  
  @media (max-width: 768px) {
    body {
      flex-direction: column;
      padding: 10px;
    }
  
    .container {
      padding: 10%;
      text-align: center;
    }
  
    .header {
      flex-direction: column;
      align-items: center;
    }
  
    .headerText {
      font-size: 8vw;
      margin-bottom: 8px;
    }
  
    .badge {
      font-size: 4vw;
      margin: 0;
      margin-top: 8px;
    }
  
    .heading {
      font-size: 6vw;
      margin-top: 16px;
    }
  
    .paragraph {
      font-size: 3vw;
      margin-top: 8px;
    }
  
    .ctaButton {
      width: 20vw;
      height: 20vw;
      font-size: 3vw;
    }
  
    .paragraph2 {
      font-size: 2.5vw;
      margin-top: 8px;
    }
  }
  

